import React from "react"
import { Layout } from "../layout"
import { returnLink } from "../../helpers/returnLink"
import { CustomButton } from "../common/CustomButton"
import DrThomasStock from "../../images/experts/DrStock.webp"
import DrSurenNaidoo from "../../images/experts/DrSuren.webp"
import DrFredEdwards from "../../images/experts/DrFred.webp"

const Authors = ({ prefix }) => {
  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "authormenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "authorbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <main>
        <div className="width__container mb-200 mt-40 sm:mt-80">
          {prefix !== "uk" ? (
            <>
              <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] md:flex-row">
                <img
                  src={DrThomasStock}
                  alt="Picture of Dr. Thomas Stock"
                  className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
                />
                <article className="flex flex-col gap-2">
                  <h1 className="text-xl font-bold">Dr. Thomas Stock</h1>
                  <h2 className="font-medium">MBChB (Hon) MSc.</h2>
                  <p>
                    {prefix === "fr-ca"
                      ? "Dr. Stock est un médecin généraliste qualifié qui reconnaît l'effet de l'ED et de la PE sur le psyche des hommes. Il apporte à Clinique de Santé Masculine ses connaissances et ses compétences pour offrir un soin centré sur le patient."
                      : "Dr. Stock is a qualified GP who recognises the effect ED and PE can have on the male psyche. He brings both his knowledge and expertise to MHC providing patient centred care."}
                  </p>
                </article>
                <CustomButton
                  className="orangeFilled slim__btn"
                  text={
                    prefix === "fr-ca" ? "Voir les articles" : "View Articles"
                  }
                  navLink={`/${prefix}/resources/author/dr-thomas-stock`}
                />
              </section>
              <hr className="mb-40" />
              <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] md:flex-row">
                <img
                  src={DrSurenNaidoo}
                  alt="Picture of Dr. Suren Naidoo"
                  className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
                />
                <article className="flex flex-col gap-2">
                  <h1 className="text-xl font-bold">Dr. Suren Naidoo</h1>
                  <h2 className="font-medium">MRCGP, MBBCh</h2>
                  <p>
                    {prefix === "fr-ca"
                      ? "Comme médecin généraliste privé et praticien en esthétique, Dr. Suren pratique la médecine avec une approche holistique en écoutant et dédiant le meilleur soin possible à ses patients."
                      : "As a private GP and Aesthetic Practitioner, Dr. Suren practices medicine with a holistic approach by listening and dedicating the best possible care to his patients."}
                  </p>
                </article>
                <CustomButton
                  className="orangeFilled slim__btn"
                  text={
                    prefix === "fr-ca" ? "Voir les articles" : "View Articles"
                  }
                  navLink={`/${prefix}/resources/author/dr-suren-naidoo`}
                />
              </section>
            </>
          ) : (
            <>
              <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] md:flex-row">
                <img
                  src={DrSurenNaidoo}
                  alt="Picture of Dr. Suren Naidoo"
                  className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
                />
                <article className="flex flex-col gap-2">
                  <h1 className="text-xl font-bold">Dr. Suren Naidoo</h1>
                  <h2 className="font-medium">MRCGP, MBBCh</h2>
                  <p>
                    {prefix === "fr-ca"
                      ? "Comme médecin généraliste privé et praticien en esthétique, Dr. Suren pratique la médecine avec une approche holistique en écoutant et dédiant le meilleur soin possible à ses patients."
                      : "As a private GP and Aesthetic Practitioner, Dr. Suren practices medicine with a holistic approach by listening and dedicating the best possible care to his patients."}
                  </p>
                </article>
                <CustomButton
                  className="orangeFilled slim__btn"
                  text="View Articles"
                  navLink={`/${prefix}/resources/author/dr-suren-naidoo`}
                />
              </section>
              <hr className="mb-40" />
              <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] md:flex-row">
                <img
                  src={DrThomasStock}
                  alt="Picture of Dr. Thomas Stock"
                  className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
                />
                <article className="flex flex-col gap-2">
                  <h1 className="text-xl font-bold">Dr. Thomas Stock</h1>
                  <h2 className="font-medium">MBChB (Hon) MSc.</h2>
                  <p>
                    {prefix === "fr-ca"
                      ? "Dr. Stock est un médecin généraliste qualifié qui reconnaît l'effet de l'ED et de la PE sur le psyche des hommes. Il apporte à Clinique de Santé Masculine ses connaissances et ses compétences pour offrir un soin centré sur le patient."
                      : "Dr. Stock is a qualified GP who recognises the effect ED and PE can have on the male psyche. He brings both his knowledge and expertise to MHC providing patient centred care."}
                  </p>
                </article>
                <CustomButton
                  className="orangeFilled slim__btn"
                  text="View Articles"
                  navLink={`/${prefix}/resources/author/dr-thomas-stock`}
                />
              </section>
            </>
          )}

          <hr className="mb-40" />
          <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] md:flex-row">
            <img
              src={DrFredEdwards}
              alt="Picture of Dr. Fred Edwards"
              className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
            />
            <article className="flex flex-col gap-2">
              <h1 className="text-xl font-bold">Dr. Fred Edwards</h1>
              <h2 className="font-medium">MBBS, FRACGP</h2>
              <p>
                {prefix === "fr-ca"
                  ? "Dr. Fred Edwards est un médecin généraliste qualifié qui a plus de 30 ans d'expérience. Il vise à élargir le champ de traitement au sein de Clinique de Santé Masculine pour inclure des problèmes de santé générale pour aider les hommes."
                  : "Dr. Fred Edwards is a qualified GP and has over 30+ years’ experience. He aims to widen the scope of treatment within MHC to include general health issues to help men."}
              </p>
            </article>
            <CustomButton
              className="orangeFilled slim__btn"
              text={prefix === "fr-ca" ? "Voir les articles" : "View Articles"}
              navLink={`/${prefix}/resources/author/dr-fred-edwards`}
            />
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default Authors
